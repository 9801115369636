<template>
	<div>
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item><i class="el-icon-lx-settings"></i> 用户管理</el-breadcrumb-item>
		</el-breadcrumb> -->
		<div class="conbox">
			<div class="sub_til" style="margin-top: 20px;">用户管理</div>
			<el-form :inline="true" :model="query" class="query-form" size="mini">
				<el-form-item class="query-form-item">
					<el-input v-model="query.name" placeholder="姓名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button-group>
						<el-button type="primary" icon="el-icon-refresh" @click="onReset()"></el-button>
						<el-button type="primary" icon="search" @click="onSubmit()">查询</el-button>
						<el-button type="primary" @click="handleForm(null, null)">新增用户</el-button>
					</el-button-group>
				</el-form-item>
			</el-form>
			<el-table :data="list" max-height="500" border style="width: 100%">
				<el-table-column prop="name" label="姓名"> </el-table-column>
				<el-table-column prop="mobile" label="用户名"> </el-table-column>
				<el-table-column prop="create_time" label="创建时间"> </el-table-column>
				<el-table-column prop="remark" label="备注"> </el-table-column>
				<el-table-column label="操作" fixed="right">
					<template #default="scope">
						<el-button type="text" size="small" icon="el-icon-edit"
							@click="handleForm(scope.$index, scope.row)">编辑</el-button>
						<el-button type="text" size="small" @click="handleActive(scope.$index, scope.row)">重置密码
						</el-button>
						<el-button type="text" size="small" @click="handleDel(scope.$index, scope.row)" icon="el-icon-delete" class="red">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="total" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<!--新增/编辑弹窗内容-->
		<el-dialog :title="formMap[formName]" v-model="formVisible" :before-close="hideForm" width="85%" top="5vh">
			<el-form :model="formData" :rules="formRules" ref="dataForm">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="formData.name" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="电话号" prop="mobile">
					<el-input v-model="formData.mobile" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input type="textarea" v-model="formData.remark"></el-input>
				</el-form-item>
			</el-form>
			<div class="dialog-footer">
				<el-button @click="hideForm">取消</el-button>
				<el-button type="primary" @click="formSubmit()" :loading="formLoading">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		usersList,
		useradminSave,
		useradminDelete,
		useradminActive
	} from "../api/users";
	const formJson = {};
	export default {
		data() {
			// let validatePass = (rule, value, callback) => {
			// 	if (value === "") {
			// 		callback(new Error("请输入密码"));
			// 	} else {
			// 		callback();
			// 	}
			// };
			// let validatePass2 = (rule, value, callback) => {
			// 	if (value === "") {
			// 		callback(new Error("请再次输入密码"));
			// 	} else if (value !== this.formData.user_pw) {
			// 		callback(new Error("两次输入密码不一致!"));
			// 	} else {
			// 		callback();
			// 	}
			// };
			return {
				query: {
					page: 1,
					pageSize: 15,
				},
				list: [],
				total: 0,
				loading: true,
				formName: null,
				formMap: {
					add: "新增",
					edit: "编辑",
				},
				formLoading: false,
				formVisible: false,
				formData: formJson,
				formRules: {
					name: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur"
					}],
					mobile: [{
						required: true,
						message: "请输入电话号",
						trigger: "blur"
					}]
				},
				deleteLoading: false,
			};
		},
		methods: {
			onReset() {
				this.$router.push({
					path: "",
				});
				this.query = {
					page: 1,
					pageSize: 15,
				};
				this.getList();
			},
			onSubmit() {
				this.$router.push({
					path: "",
					query: this.query,
				});
				this.getList();
			},
			handlePageChange(val) {
				this.query.page = val;
				this.getList();
			},
			getList() {
				this.loading = true;
				usersList(this.query)
					.then((res) => {
						this.loading = false;
						console.log(res)
						this.list = res.data.data || [];
						this.total = res.data.total || 0;
					})
					.catch(() => {
						this.loading = false;
						this.list = [];
						this.total = 0;
					});
			},
			// 刷新表单
			resetForm() {
				if (this.$refs["dataForm"]) {
					// 清空验证信息表单
					this.$refs["dataForm"].clearValidate();
					// 刷新表单
					this.$refs["dataForm"].resetFields();
				}
			},
			// 隐藏表单
			hideForm() {
				// 更改值
				this.formVisible = !this.formVisible;
				// 清空表单
				//this.$refs["dataForm"].resetFields();
				this.resetForm();
				return true;
			},
			// 显示表单
			handleForm(index, row) {
				this.formVisible = true;
				this.formData = JSON.parse(JSON.stringify(formJson));
				if (row !== null) {
					this.formData = Object.assign({}, row);
					// console.log(row)
					this.formData.roleId = row.role_id;
				}
				this.formName = "add";
				// this.formRules = this.addRules;
				if (index !== null) {
					this.index = index;
					this.formName = "edit";
					// this.formRules = this.editRules;
				}
			},
			formSubmit() {
				this.$refs["dataForm"].validate((valid) => {
					if (valid) {
						this.formLoading = true;
						let data = Object.assign({}, this.formData);
						useradminSave(data, this.formName).then((res) => {
							this.formLoading = false;
							if (res.code != 200) {
								this.$message.error(res.msg);
								return false;
							}
							this.$message.success("操作成功");
							this.formVisible = false;
							this.getList();
						});
					}
				});
			},
			// 删除
			handleDel(index, row) {
				this.$confirm("您确定要删除该用户吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						useradminDelete(row.id).then((res) => {
							// console.log(res);
							if (res.code == 200) {
								this.$message.success(res.msg);
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
			// 启用
			handleActive(index, row) {
				this.$confirm("您确定要重置密码吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						useradminActive(row.id).then((res) => {
							// console.log(res);
							if (res.code == 200) {
								this.$message.success(res.msg);
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
		},
		mounted() {},
		created() {
			// 加载表格数据
			this.getList();
		},
	};
</script>

<style>
	.red {
		color: #ff0000;
	}

	.red:hover {
		color: #ff0000;
	}

	.red:active {
		color: #ff0000;
	}
</style>
